import http from "./http-common"
import {getData, postData} from "../handlers";

export default class AppServices {
    static settings = (queryString) => {
        return getData(http, `/app/system/settings${queryString ? `?${queryString}` : ""}`);
    }

    static stories = (queryString) => {
        return getData(http, `/app/stories${queryString ? `?${queryString}` : ""}`);
    }

    static localizations = (queryString) => {
        return getData(http, `/app/system/localizations${queryString ? `?${queryString}` : ""}`);
    }

    static menu = (menuName) => {
        return getData(http, `/app/system/menu/${menuName}`);
    }

    static page = (pageName) => {
        return getData(http, `/app/pages/${pageName}`);
    }

    static pages = () => {
        return getData(http, `/app/pages`);
    }

    static request = (data) => {
        return postData(http, `/app/system/request/template`, data)
    }

    static address = async (lat, lon) => {
        const {data: response} = await http.get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lon}`);
        return response;
    }
    
    static promoCodes = () => {
        return getData(http, `/app/promoCodes`)
    }

    static locations = (id) => {
        return getData(http, `/app/locations/cities${id ? `?franchise_id=${id}` : ''}`)
    }

    static search = async (queryString) => {
        const temp = JSON.parse(localStorage.getItem('city'));
        let str =  queryString.replace(' ', '+');
        return getData(http, `/app/locations/map/search?text=${str}&city_id=${temp.value}`)
    }

    static suggest = async (queryString) => {
        let str =  queryString.replace(' ', '+');
        return getData(http, `/app/locations/map/suggest?text=${str}`)
    }
}